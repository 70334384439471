import { useCustomer } from "~/composables/useCustomer.js";

export default defineNuxtRouteMiddleware(async () => {
  const customer = useCustomer();

  if (customer.value) {
    return navigateTo(
      {
        name: "profile",
      },
      { replace: true },
    );
  }
});
